import firebase from "firebase";
import "firebase/auth";
import "firebase/firestore";
import "firebase/messaging";

var firebaseConfig = {
  apiKey: "AIzaSyA_wbfQyOXKbdzlKQd51SxoHoD74GovuOI",
  authDomain: "novo-67e2a.firebaseapp.com",
  projectId: "novo-67e2a",
  storageBucket: "novo-67e2a.appspot.com",
  messagingSenderId: "101606730812",
  appId: "1:101606730812:web:2c86be66450c5948d9a739",
  measurementId: "G-P5DXDXJPXE",
};

firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
const auth = firebase.auth();
const messaging = firebase.messaging.isSupported()
  ? firebase.messaging()
  : null;

export { db, messaging, auth };
export default firebase;
