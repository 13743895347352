import { Modal, Table } from "antd";
import moment from "moment";
import { useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { hideModal } from "../../../redux/reducers/modalSlice";

const ViewIncidentVersionModal = () => {
  const dispatch = useAppDispatch();
  const { show, versions } = useAppSelector((state) => state.modal.modalProps);

  const closeHandler = () => {
    dispatch(hideModal());
  };

  const columns: any[] = useMemo(() => {
    return [
      {
        title: "Revision Done By",
        dataIndex: "user",
        key: "user",
      },
      {
        title: "Revision Date",
        dataIndex: "updatedDate",
        key: "updatedDate",
      },
      {
        title: "Reason",
        dataIndex: "reason",
        key: "reason",
      },
      {
        title: "Previous Value",
        dataIndex: "prevValue",
        key: "prevValue",
        render: (data: any) => {
          return (
            <>
              {data ? (
                <>
                  <div className="fw-semibold font-m text-prime mb-2">
                    {data?.type +
                      (data?.typeName ? " - " + data?.typeName : "")}
                  </div>
                  <div className="mb-2">
                    <span className="fw-medium text-prime me-1">Date : </span>
                    <span>
                      {data?.reportedOn
                        ? moment(data?.reportedOn).format("DD/MM/YYYY")
                        : "-"}
                    </span>
                  </div>
                  <div className="mb-2">
                    <span className="fw-medium text-prime me-1">
                      Comment :{" "}
                    </span>
                    <span>{data?.comment || "-"}</span>
                  </div>
                </>
              ) : (
                "-"
              )}
            </>
          );
        },
      },
      {
        title: "New Value",
        dataIndex: "newValue",
        key: "newValue",
        render: (data: any) => {
          return (
            <>
              {data ? (
                <>
                  <div className="fw-semibold font-m text-prime mb-2">
                    {data?.type +
                      (data?.typeName ? " - " + data?.typeName : "")}
                  </div>
                  <div className="mb-2">
                    <span className="fw-medium text-prime me-1">Date : </span>
                    <span>
                      {data?.reportedOn
                        ? moment(data?.reportedOn).format("DD/MM/YYYY")
                        : "-"}
                    </span>
                  </div>
                  <div className="mb-2">
                    <span className="fw-medium text-prime me-1">
                      Comment :{" "}
                    </span>
                    <span>{data?.comment || "-"}</span>
                  </div>
                </>
              ) : (
                "-"
              )}
            </>
          );
        },
      },
    ];
  }, []);

  return (
    <Modal
      title={"AE Submission Logs"}
      visible={show}
      onCancel={closeHandler}
      width={"60rem"}
      footer={null}
    >
      <Table
        dataSource={versions}
        columns={columns}
        className="singer-custom-table"
        rowKey={(record) => record.id}
        pagination={false}
      />
    </Modal>
  );
};

export default ViewIncidentVersionModal;
