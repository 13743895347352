import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { Loader } from "./components/Common/Loader";
import { AuthenticatedRoute } from "./components/Common/RouteComponents";
import Sites from "./components/Sites/Sites";
import IncidentReporting from "./components/IncidentReporting/IncidentReporting";
import MyStudies from "./components/MyStudies/MyStudies";

const AppLayout = React.lazy(() => import("./components/AppLayout/AppLayout"));

const DashboardRoutes = React.lazy(
  () => import("./components/Dashboard/DashboardRoutes")
);
const AuthRoutes = React.lazy(
  () => import("./components/AuthLayout/AuthRoutes")
);
const QuestionRoutes = React.lazy(
  () => import("./components/Questions/QuestionRoutes")
);
const CMSRoutes = React.lazy(() => import("./components/CMS/CmsRoutes"));

const Queries = React.lazy(() => import("./components/Queries/Queries"));

const Chat = React.lazy(() => import("./components/Chat/Chat"));

const PatientDiaryRoutes = React.lazy(
  () => import("./components/PatientDiary/PatientDiaryRoutes")
);

const AppointmentRoutes = React.lazy(
  () => import("./components/Appointments/AppointmentRoutes")
);

const FolderDocument = React.lazy(
  () => import("./components/Documents/FolderDocument")
);

const ExceriseDiary = React.lazy(
  () => import("./components/Diary/ExerciseDiaryList")
);

const AuditLog = React.lazy(() => import("./components/AuditLogs/AuditLog"));

const WebviewWrapper = React.lazy(
  () => import("./components/CMS/Webview/WebviewWrapper")
);

const Study = React.lazy(() => import("./components/Study/Study"));

const ParticipantsRoutes = React.lazy(
  () => import("./components/Participants/ParticipantRoutes")
);

const Administrator = React.lazy(
  () => import("./components/Administrators/Administrator")
);

const CRF = React.lazy(() => import("./components/CRF/CRF"));

function AllRoutes() {
  return (
    <React.Suspense fallback={<Loader />}>
      <Routes>
        {/* Open routes */}
        <Route path="/lesson/read/:id" element={<WebviewWrapper />} />
        {/* AuthenticatedRoutes */}
        <Route path="/auth/*" element={<AuthRoutes />} />
        <Route
          path="/my-studies"
          element={
            <AuthenticatedRoute>
              <MyStudies />
            </AuthenticatedRoute>
          }
        />
        <Route element={<AppLayout />}>
          <Route
            path="/study"
            element={
              <AuthenticatedRoute>
                <Study />
              </AuthenticatedRoute>
            }
          />
          <Route path="/question/*" element={<QuestionRoutes />} />
          <Route path="/cms/*" element={<CMSRoutes />} />
          <Route path="/dashboard/*" element={<DashboardRoutes />} />
          <Route path="/appointments/*" element={<AppointmentRoutes />} />
          <Route path="/patient-diary/*" element={<PatientDiaryRoutes />} />
          <Route path="/participants/*" element={<ParticipantsRoutes />} />
          <Route
            path="/crf/:crfType/:id"
            element={
              <AuthenticatedRoute
                disallowedRoles={[
                  "education_content_manager",
                  "technical_support",
                ]}
              >
                <CRF />
              </AuthenticatedRoute>
            }
          />
          <Route
            path="/administrator"
            element={
              <AuthenticatedRoute>
                <Administrator />
              </AuthenticatedRoute>
            }
          />
          <Route
            path="/diary"
            element={
              <AuthenticatedRoute>
                <ExceriseDiary />
              </AuthenticatedRoute>
            }
          />
          <Route
            path="/audit-logs"
            element={
              <AuthenticatedRoute
                allowedRoles={["data_manager", "system_admin"]}
              >
                <AuditLog />
              </AuthenticatedRoute>
            }
          />
          <Route
            path="/documents/:docId"
            element={
              <AuthenticatedRoute>
                <FolderDocument />
              </AuthenticatedRoute>
            }
          />
          <Route
            path="/chat/:id"
            element={
              <AuthenticatedRoute>
                <Chat />
              </AuthenticatedRoute>
            }
          />
          <Route
            path="/queries"
            element={
              <AuthenticatedRoute>
                <Queries />
              </AuthenticatedRoute>
            }
          />
          <Route
            path="/sites"
            element={
              <AuthenticatedRoute
                allowedRoles={["data_manager", "system_admin"]}
              >
                <Sites />
              </AuthenticatedRoute>
            }
          />
          <Route
            path="/adverse-events"
            element={
              <AuthenticatedRoute>
                <IncidentReporting />
              </AuthenticatedRoute>
            }
          />
        </Route>
        <Route path="/" element={<Navigate to="/auth/login" />} />
        <Route path="*" element={<Navigate to="/auth/login" />} />
      </Routes>
    </React.Suspense>
  );
}

export default AllRoutes;
