import { Modal, Radio, Table, Tabs, Checkbox, Button } from "antd";
import { useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { hideModal } from "../../../redux/reducers/modalSlice";
import CustomButton from "../../Common/CustomButton";

const { TabPane } = Tabs;

const data = [
  {
    role: "Master Admin",
    add: true,
    view: true,
    edit: true,
    email: true,
    rand: true,
    view_rand: true,
    sign: true,
    lock: true,
    verify: true,
    query: true,
    archive: true,
    export: true,
    survey: true,
    view_survey: true,
  },
  {
    role: "Study coordinator",
    add: true,
    view: true,
    edit: true,
    email: true,
    rand: false,
    view_rand: false,
    sign: true,
    lock: true,
    verify: true,
    query: true,
    archive: true,
    export: true,
    survey: true,
    view_survey: true,
  },
  {
    role: "Facilitator",
    add: true,
    view: true,
    edit: true,
    email: false,
    rand: false,
    view_rand: false,
    sign: true,
    lock: false,
    verify: false,
    query: true,
    archive: true,
    export: false,
    survey: true,
    view_survey: true,
  },
  {
    role: "Investigator",
    add: true,
    view: true,
    edit: true,
    email: false,
    rand: false,
    view_rand: false,
    sign: true,
    lock: true,
    verify: true,
    query: true,
    archive: true,
    export: true,
    survey: false,
    view_survey: true,
  },
  {
    role: "Data Manager",
    add: true,
    view: true,
    edit: true,
    email: true,
    rand: true,
    view_rand: true,
    sign: true,
    lock: true,
    verify: true,
    query: true,
    archive: true,
    export: true,
    survey: true,
    view_survey: true,
  },
  {
    role: "Data Entry",
    add: true,
    view: true,
    edit: true,
    email: true,
    rand: false,
    view_rand: false,
    sign: true,
    lock: true,
    verify: true,
    query: true,
    archive: true,
    export: false,
    survey: true,
    view_survey: true,
  },
  {
    role: "Rater",
    add: true,
    view: true,
    edit: true,
    email: true,
    rand: false,
    view_rand: false,
    sign: true,
    lock: false,
    verify: true,
    query: true,
    archive: true,
    export: false,
    survey: false,
    view_survey: true,
  },
  // {
  //   role: "Technical Support",
  //   add: false,
  //   view: true,
  //   edit: false,
  //   email: false,
  //   rand: false,
  //   view_rand: false,
  //   sign: false,
  //   lock: false,
  //   verify: true,
  //   query: false,
  //   archive: false,
  //   export: false,
  //   survey: false,
  //   view_survey: false,
  // },
  // {
  //   role: "Education Content Manager",
  //   add: false,
  //   view: true,
  //   edit: false,
  //   email: false,
  //   rand: false,
  //   view_rand: false,
  //   sign: false,
  //   lock: false,
  //   verify: false,
  //   query: true,
  //   archive: false,
  //   export: false,
  //   survey: false,
  //   view_survey: false,
  // },
];
const RightsModal = () => {
  const dispatch = useAppDispatch();
  const { show } = useAppSelector((state) => state.modal.modalProps);

  const closeHandler = () => {
    dispatch(hideModal());
  };

  const columns: any = useMemo(() => {
    return [
      {
        title: "Role Name",
        dataIndex: "role",
        key: "role",
        width: 200,
        render: (role: string) => {
          return <div className="fw-semibold text-prime">{role}</div>;
        },
      },
      {
        title: "Add",
        key: "add",
        dataIndex: "add",
        align: "center",
        width: 100,
        render: (value: boolean, record: any) => {
          return <Checkbox defaultChecked={value} />;
        },
      },
      {
        title: "View",
        key: "view",
        dataIndex: "view",
        align: "center",
        width: 100,
        render: (value: boolean, record: any) => {
          return <Checkbox defaultChecked={value} />;
        },
      },
      {
        title: "Edit",
        key: "edit",
        dataIndex: "edit",
        align: "center",
        width: 100,
        render: (value: boolean, record: any) => {
          return <Checkbox defaultChecked={value} />;
        },
      },
      {
        title: "Email",
        key: "email",
        dataIndex: "email",
        align: "center",
        width: 100,
        render: (value: boolean, record: any) => {
          return <Checkbox defaultChecked={value} />;
        },
      },
      {
        title: "Randomize records",
        key: "rand",
        dataIndex: "rand",
        align: "center",
        width: 110,
        ellipsis: true,
        render: (value: boolean, record: any) => {
          return <Checkbox defaultChecked={value} />;
        },
      },
      {
        title: "View randomization records",
        key: "View randomization records",
        dataIndex: "view_rand",
        align: "center",
        width: 130,
        ellipsis: true,
        render: (value: boolean, record: any) => {
          return <Checkbox defaultChecked={value} />;
        },
      },
      {
        title: "Sign",
        key: "sign",
        dataIndex: "sign",
        align: "center",
        width: 100,
        render: (value: boolean, record: any) => {
          return <Checkbox defaultChecked={value} />;
        },
      },
      {
        title: "Lock",
        key: "lock",
        dataIndex: "lock",
        align: "center",
        width: 100,
        render: (value: boolean, record: any) => {
          return <Checkbox defaultChecked={value} />;
        },
      },
      {
        title: "Verify",
        key: "verify",
        dataIndex: "verify",
        align: "center",
        width: 100,
        render: (value: boolean, record: any) => {
          return <Checkbox defaultChecked={value} />;
        },
      },
      {
        title: "Query",
        key: "query",
        dataIndex: "query",
        align: "center",
        width: 100,
        render: (value: boolean, record: any) => {
          return <Checkbox defaultChecked={value} />;
        },
      },
      {
        title: "Archive",
        key: "archive",
        dataIndex: "archive",
        align: "center",
        width: 100,
        render: (value: boolean, record: any) => {
          return <Checkbox defaultChecked={value} />;
        },
      },
      {
        title: "Export",
        key: "export",
        dataIndex: "export",
        align: "center",
        width: 100,
        render: (value: boolean, record: any) => {
          return <Checkbox defaultChecked={value} />;
        },
      },
      {
        title: "Send surveys",
        key: "send surveys",
        dataIndex: "survey",
        align: "center",
        width: 100,
        render: (value: boolean, record: any) => {
          return <Checkbox defaultChecked={value} />;
        },
      },
      {
        title: "View surveys",
        key: "view surveys",
        dataIndex: "view_survey",
        align: "center",
        width: 100,
        render: (value: boolean, record: any) => {
          return <Checkbox defaultChecked={value} />;
        },
      },
    ];
  }, []);

  return (
    <Modal
      title={"User Rights"}
      visible={show}
      onCancel={closeHandler}
      width={"70rem"}
      footer={null}
    >
      <div>
        {/* <Tabs className="therapist-slot-tab singer-tabs-color"> */}
        {/* <TabPane tab="Institute Rights" key="Institute Rights"> */}
        <Table
          columns={columns}
          dataSource={data}
          pagination={false}
          scroll={{ x: 200 }}
          rowKey={(record) => record.role}
        />
        {/* </TabPane> */}
        {/* <TabPane tab="Management Rights" key="Management Rights">
            <div className="d-flex">
              <span className="me-5 fw-semibold" style={{ width: "150px" }}>
                Manage Records
              </span>
              <Radio.Group
                defaultValue="no"
                className="d-flex  mb-4 justify-content-center"
              >
                <Radio value="yes">Yes</Radio>
                <Radio value="no">No</Radio>
              </Radio.Group>
            </div>
            <div className="d-flex">
              <span className="me-5 fw-semibold" style={{ width: "150px" }}>
                Manage Forms
              </span>
              <Radio.Group
                defaultValue="no"
                className="d-flex  mb-4 justify-content-center"
              >
                <Radio value="yes">Yes</Radio>
                <Radio value="no">No</Radio>
              </Radio.Group>
            </div>
            <div className="d-flex">
              <span className="me-5 fw-semibold" style={{ width: "150px" }}>
                Manage Users
              </span>
              <Radio.Group
                defaultValue="no"
                className="d-flex  mb-4 justify-content-center"
              >
                <Radio value="yes">Yes</Radio>
                <Radio value="no">No</Radio>
              </Radio.Group>
            </div>
            <div className="d-flex">
              <span className="me-5 fw-semibold" style={{ width: "150px" }}>
                Manage Settings
              </span>
              <Radio.Group
                defaultValue="no"
                className="d-flex  mb-4 justify-content-center"
              >
                <Radio value="yes">Yes</Radio>
                <Radio value="no">No</Radio>
              </Radio.Group>
            </div>
          </TabPane> */}
        {/* </Tabs> */}
        <div className="d-flex align-items-center mt-4">
          <Button
            type="primary"
            style={{ height: "40px", borderRadius: "12px" }}
            className="me-4"
            onClick={closeHandler}
          >
            <span className="fw-semibold font-sm">Save</span>
          </Button>
          <Button
            style={{ height: "40px", borderRadius: "12px" }}
            className="me-4"
            onClick={closeHandler}
          >
            <span className="fw-semibold font-sm">Cancel</span>
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default RightsModal;
