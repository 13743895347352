import { Select, Table } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { errorToastMessage } from "../../helpers/toastMessage";
import http from "../../http";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setAppLoader } from "../../redux/reducers/loaderSlice";
import { CustomPagination, PaginationText } from "../Common/TableUtils";
import { useNavigate } from "react-router-dom";

const IncidentReporting: React.FC<any> = () => {
  const dispatch = useAppDispatch();
  const [data, setData] = useState<any[]>([]);
  const [current, setCurrent] = useState(1);
  const [total, setTotal] = useState(0);
  const navigate = useNavigate();
  const [site, setSite] = useState("all");
  const { sites: siteList } = useAppSelector((state) => state.user);

  useEffect(() => {
    const fetchIncidents = async () => {
      try {
        dispatch(setAppLoader(true));
        let siteStr = "";
        if (site && site !== "all") {
          siteStr += `&siteId=${site}`;
        }
        const res = await http.get(
          `/mh/incident-reports-list?page=${current}&size=8${siteStr}`
        );

        const incidents = res.data.data.rows.map((incident: any) => {
          return {
            id: incident.id,
            participantId: incident?.participantId,
            subjectId: incident?.participant?.participant?.subjectId,
            type: incident.type,
            typeName: incident.typeName,
            reportedOn: incident.reportedOn
              ? moment(incident.reportedOn).format("DD/MM/YYYY")
              : null,
            draft: incident.draft || false,
            comment: incident.comment,
            versions: incident?.versions?.map((version: any) => {
              return {
                id: version.id,
                reason: version.reason || "-",
                updatedDate: moment(version.createdAt).format(
                  "DD/MM/YYYY hh:mm A"
                ),
                user: version?.who
                  ? version?.who?.firstName + " " + version?.who?.lastName
                  : "-",
                newValue: version?.newValues,
                prevValue: version?.prevValues,
              };
            }),
          };
        });
        setTotal(res.data.data.count);
        setData(incidents);
        dispatch(setAppLoader(false));
      } catch (err) {
        dispatch(setAppLoader(false));
        errorToastMessage(err as Error);
      }
    };
    fetchIncidents();
  }, [dispatch, setData, current, site]);

  const crfNavigate = (patient: any) => {
    navigate(
      `/crf/study/${patient?.participantId}?patientId=${patient?.subjectId}`
    );
  };

  const columns = [
    {
      title: "Subject Id",
      dataIndex: "subjectId",
      key: "subjectId",
      render: (_1: any, record: any) => {
        return (
          <span
            onClick={() => crfNavigate(record)}
            className="color-primary cp"
          >
            {record?.subjectId || "-"}
          </span>
        );
      },
    },
    {
      title: "AE Type",
      dataIndex: "type",
      key: "type",
      render: (type: string, record: any) => {
        return type + (record.typeName ? " - " + record.typeName : "");
      },
    },
    {
      title: "Date",
      dataIndex: "reportedOn",
      key: "date",
      render: (date: string) => {
        return date || "-";
      },
    },
    {
      title: "Comment",
      dataIndex: "comment",
      key: "comment",
      render: (comment: string) => {
        return comment || "-";
      },
    },
  ];

  const onTableChange = async (pagination: any) => {
    if (pagination.current) {
      setCurrent(pagination.current);
    }
  };

  const changeSite = (val: string) => {
    setSite(val);
    setCurrent(1);
  };

  return (
    <div className="cms-wrapper">
      <div className="content-header">
        <div className="font-m fw-semibold font-l me-5 text-secondary">AE</div>
        <span style={{ marginLeft: "auto" }}></span>
        <Select
          style={{ maxWidth: "300px", marginRight: "16px" }}
          value={site}
          onChange={changeSite}
          className="custom-select-field"
          size="large"
        >
          <Select.Option value="all">All</Select.Option>
          {siteList.map((site) => (
            <Select.Option value={site.value} key={site.value}>
              {site.label}
            </Select.Option>
          ))}
        </Select>
      </div>
      <div className="modules-wrapper question-module-wrapper">
        <Table
          dataSource={data}
          columns={columns}
          className="singer-custom-table"
          rowKey={(record) => record.id}
          onChange={onTableChange}
          scroll={{ x: "max-content" }}
          pagination={{
            current: current,
            total: total,
            showSizeChanger: false,
            pageSize: 8,
            hideOnSinglePage: true,
            className: "singer-table-pagination",
            itemRender: CustomPagination,
            showTotal: PaginationText,
            position: ["bottomLeft"],
          }}
        />
      </div>
    </div>
  );
};

export default IncidentReporting;
