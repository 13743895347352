import { useAppDispatch } from "../../../redux/hooks";
import { hideModal } from "../../../redux/reducers/modalSlice";
import { Button, Modal, Progress } from "antd";
import { useCallback, useEffect, useState } from "react";
import { errorToastMessage } from "../../../helpers/toastMessage";
import http from "../../../http";
import { getStudyId } from "../../../helpers/study";
import { useInterval } from "../../../helpers/customHooks";
import { DownloadIcon } from "@heroicons/react/solid";

type Props = {
  show: boolean;
  jobId: string;
  downloadPrefined?: boolean;
};

const DownloadModal: React.FC<Props> = ({ show, jobId, downloadPrefined }) => {
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState(false);
  const [path, setPath] = useState<string | null>(null);
  const dispatch = useAppDispatch();
  const closeHandler = () => {
    dispatch(hideModal());
  };

  const callback = useCallback(async () => {
    try {
      const studyId = getStudyId();
      const res = await http.get(
        `/study/${studyId}/phase/export/${jobId}/status`
      );
      const { path, progress, status } = res.data.data;
      if (status === "failed") {
        setError(true);
      } else {
        setProgress(progress);
        if (progress === 100) {
          setPath(path);
        }
      }
    } catch (err) {
      errorToastMessage(err as Error);
      dispatch(hideModal());
    }
  }, [setProgress, setPath, jobId, dispatch, setError]);

  useInterval(callback, path || error ? null : 5000);

  useEffect(() => {
    callback();
  }, [callback]);

  const handleButtonClick = () => {
    if (downloadPrefined) {
      window.open(
        "https://storage.googleapis.com/mahalo-connect-qxoqjf/files/study_export.xml",
        "_blank"
      );
      closeHandler();
    } else {
      if (path) {
        window.open(path, "_blank");
        closeHandler();
      }
    }
  };

  return (
    <Modal
      title="Exporting"
      visible={show}
      onCancel={closeHandler}
      width={"25rem"}
      footer={null}
    >
      <div className="d-flex flex-column align-items-center">
        <div className="mb-3">
          <Progress
            type="circle"
            percent={progress}
            status={error ? "exception" : "normal"}
          />
        </div>
        <div>
          {path ? (
            <>
              <div className="font-m fw-semibold mb-3">Export Complete</div>
              <Button
                onClick={handleButtonClick}
                className="d-flex align-items-center"
                style={{ height: "40px", borderRadius: "12px" }}
                type="primary"
              >
                <DownloadIcon className="hero-small-icon me-2" />
                <span className="fw-semibold font-sm">Download</span>
              </Button>
            </>
          ) : error ? (
            <div className="font-m fw-semibold">Something went wrong</div>
          ) : (
            <div className="font-m fw-semibold">Export in progress</div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default DownloadModal;
