import { Button, Input, Modal } from "antd";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { hideModal } from "../../../redux/reducers/modalSlice";

const AddRoleModal = () => {
  const dispatch = useAppDispatch();
  const { show } = useAppSelector((state) => state.modal.modalProps);

  const closeHandler = () => {
    dispatch(hideModal());
  };

  return (
    <Modal
      title="Add Role"
      visible={show}
      onCancel={closeHandler}
      width={"45rem"}
      footer={null}
    >
      <div>
        <div className="w-100 mb-4">
          <div className="text-secondary font-m fw-medium mb-1">Role Name</div>
          <Input name="role" placeholder="Role Name" className="singer-input" />
        </div>

        <div className="d-flex mt-5">
          <Button
            type="primary"
            onClick={closeHandler}
            style={{ height: "40px", borderRadius: "12px" }}
            className="me-4"
          >
            <span className="fw-semibold font-sm">Save</span>
          </Button>
          <Button
            style={{ height: "40px", borderRadius: "12px" }}
            className="me-4"
            onClick={closeHandler}
          >
            <span className="fw-semibold font-sm">Cancel</span>
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default AddRoleModal;
