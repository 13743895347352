import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setAppLoader } from "../../redux/reducers/loaderSlice";
import { errorToastMessage } from "../../helpers/toastMessage";
import { authLogout, sleep } from "../../redux/actions/userAction";
import {
  ContentActive,
  LogoSidebar,
  Content as ContentInactive,
} from "../AppLayout/SidebarIcons";
import { Avatar, Dropdown, Layout, Menu, Modal, Select } from "antd";
import { useNavigate } from "react-router-dom";
import { setModalDetails } from "../../redux/reducers/modalSlice";
import { getImageInitialsFullname } from "../../helpers/user";
import Sider from "antd/lib/layout/Sider";
import { NavLink } from "react-router-dom";

const { Content } = Layout;

const confirm = Modal.confirm;

const dummyStudies = [
  {
    id: 1,
    name: "Singapore Geriatric Intervention Trial",
    status: "Live",
    roles: ["Data Manager", "Investigator"],
  },
  {
    id: 2,
    name: "Microbiome Derived Metabolism and Pharmacokinetics (MDM-PK)",
    status: "Not Live",
    roles: ["Study Coordinator", "Rater"],
  },
  {
    id: 3,
    name: "Continuous Glucose Monitoring in Dialysis Patients to Overcome Dysglycemia Trial (CONDOR)",
    status: "Not Live",
    roles: ["Rater"],
  },
  {
    id: 4,
    name: "(Sanotize) Nitric Oxide Releasing Mouthwash (NORM) for the Management of Oropharyngeal Candidiasis",
    status: "Not Live",
    roles: ["Data Manager", "Master Admin"],
  },
];

const MyStudies = () => {
  const authenticated = useAppSelector(
    (state) => state.user.isUserAuthenticated
  );
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [image, setImage] = useState<string | null>("");
  const [name, setName] = useState("");

  const [studies, setStudies] = useState<any[]>([]);
  const [toggleName, setToggleName] = useState(false);

  const handleNavigation = (study: any) => {
    if (study.status === "Live") navigate("/dashboard");
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      const img = localStorage.getItem("profileImage");
      setImage(img);
      const name = localStorage.getItem("fullName") || "";
      setName(name);
    }
  }, [setImage, setName, toggleName]);

  useEffect(() => {
    const fetchStudies = async () => {
      try {
        dispatch(setAppLoader(true));
        await sleep(750);
        setStudies(dummyStudies);
        dispatch(setAppLoader(false));
      } catch (err) {
        errorToastMessage(err as Error);
        dispatch(setAppLoader(false));
      }
    };
    fetchStudies();
  }, [setStudies]);

  const showConfirm = () => {
    const onOk = async () => {
      await dispatch(authLogout());
    };
    confirm({
      title: "Logout",
      content: "Are you sure you want to logout ?",
      onOk() {
        onOk();
      },
      onCancel() {},
      okText: "Yes",
      cancelText: "No",
    });
  };
  const showProfile = () => {
    dispatch(
      setModalDetails({
        type: "EDIT_PROFILE",
        modalProps: {
          show: true,
          callback: () => {
            setToggleName((prev) => !prev);
          },
        },
      })
    );
  };

  const Logout = (
    <Menu>
      <Menu.Item onClick={() => showProfile()} key="edit-profile">
        Edit Profile
      </Menu.Item>
      <Menu.Item
        onClick={() => navigate("/auth/change-password")}
        key="change-password"
      >
        Change password
      </Menu.Item>
      <Menu.Item onClick={showConfirm} key="logout" danger>
        Log out
      </Menu.Item>
    </Menu>
  );

  return (
    <Layout className={`singer-layout`}>
      <Sider
        breakpoint="md"
        collapsedWidth="0"
        width="80"
        id="custom-scrollbar"
        style={{
          height: "100vh",
          position: "fixed",
          left: 0,
        }}
      >
        <div className="sidebar nova-white-sidebar">
          <div className="logo-container mb-5">
            <LogoSidebar />
          </div>
          <div className="main-sidebar">
            <NavLink
              className={({ isActive }) => "item" + (isActive ? " active" : "")}
              to="/my-studies"
            >
              {window.location.href.endsWith("my-studies") ? (
                <ContentActive />
              ) : (
                <ContentInactive />
              )}
              <p className="nav-elements">My Studies</p>
            </NavLink>
          </div>
        </div>
      </Sider>

      <Layout>
        <Content className="content right-panel">
          {authenticated && (
            <>
              <div className="navigation">
                <div className="logo-container d-block d-md-none m-3">
                  <a href="/">
                    <LogoSidebar />
                  </a>
                </div>
                <div className="actions">
                  <div className="user">
                    <Dropdown placement="bottomRight" overlay={Logout}>
                      <div className="d-flex align-items-center cp">
                        <h6 className="d-flex m-0 pe-2">{name}</h6>
                        <div className="notification d-none d-md-flex">
                          {image !== "" ? (
                            <div className="image-container-nav user-icon-img">
                              <Avatar shape="circle" src={image} />
                            </div>
                          ) : (
                            <Avatar shape="circle">
                              {getImageInitialsFullname(name)}
                            </Avatar>
                          )}
                        </div>
                      </div>
                    </Dropdown>
                  </div>
                </div>
              </div>
              <div className="edit-study-layout">
                <div className="content-header">
                  <div className="font-m fw-bold font-l ms-5 text-secondary">
                    My Studies
                  </div>
                  <span style={{ marginLeft: "auto" }}></span>
                </div>
                <div className="h-100 p-5">
                  {studies.map((study) => {
                    return (
                      <div
                        className="dashboard-card mb-5 cp d-flex align-items-center"
                        onClick={() => handleNavigation(study)}
                        key={study.id}
                      >
                        <div
                          style={{
                            width: "100px",
                            color:
                              study.status === "Live" ? "#111928" : "#959494",
                          }}
                          className="fw-semibold"
                        >
                          {study.status}
                        </div>
                        <div className="text-prime fw-bold">{study.name}</div>
                        <div style={{ marginLeft: "auto" }}>
                          <Select
                            defaultValue={study?.roles?.[0]}
                            style={{ width: "300px", marginRight: "16px" }}
                            className="custom-select-field"
                            size="large"
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            {study.roles.map((role: string) => {
                              return (
                                <Select.Option value={role}>
                                  {role}
                                </Select.Option>
                              );
                            })}
                          </Select>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </>
          )}
        </Content>
      </Layout>
    </Layout>
  );
};

export default MyStudies;
