import { Button, Modal, Select } from "antd";
import { useState } from "react";
import { useAppDispatch } from "../../redux/hooks";
import { getStudyId } from "../../helpers/study";
import { setAppLoader } from "../../redux/reducers/loaderSlice";
import http from "../../http";
import { hideModal, setModalDetails } from "../../redux/reducers/modalSlice";
import { errorToastMessage, toastMessage } from "../../helpers/toastMessage";

type Props = {
  show: boolean;
  type: any;
};

const DownloadSelectionModal: React.FC<Props> = ({ show, type }) => {
  const dispatch = useAppDispatch();

  const closeHandler = () => {
    dispatch(hideModal());
  };

  const [exportType, setType] = useState("csv");

  const submitHandler = async () => {
    try {
      if (!exportType) {
        toastMessage("warning", "Please select an Export type to proceed");
        return;
      }
      dispatch(setAppLoader(true));
      const studyId = getStudyId();
      let addon = "";
      let body: any = {};
      switch (type) {
        case "food":
          addon = "export-food";
          break;
        case "dictionary":
          addon = "dictionary";
          break;
        case "medication":
          addon = "export-medication";
          break;
        case "incident":
          addon = "export-incident";
          break;
        case "study":
          addon = "export";
          body = {
            questionnaireIds: [],
            participantIds: [],
          };
          break;
        case "screening":
          addon = "export-screening";
          break;
      }
      if (!addon) {
        dispatch(setAppLoader(false));
        return;
      }
      const res = await http.post(`/study/${studyId}/phase/${addon}`, body);
      const { jobId } = res.data.data;
      dispatch(
        setModalDetails({
          type: `DOWNLOAD_MODAL`,
          modalProps: {
            show: true,
            jobId: jobId,
            downloadPrefined: exportType !== "csv",
          },
        })
      );
      dispatch(setAppLoader(false));
    } catch (err) {
      errorToastMessage(err as Error);
      dispatch(setAppLoader(false));
    }
  };

  const handleChange = (val: string) => {
    setType(val);
  };

  return (
    <Modal
      title="Export"
      visible={show}
      onCancel={closeHandler}
      width={"45rem"}
      footer={null}
    >
      <div>
        <div>
          <div className="text-secondary font-m fw-medium mb-1">
            Select Export Type
          </div>
          <Select
            className="custom-select-field"
            size="large"
            placeholder="Select Export Type"
            value={exportType}
            onChange={handleChange}
          >
            <Select.Option value="csv">CSV</Select.Option>
            <Select.Option value="cdisc">CDISC</Select.Option>
          </Select>
        </div>
        <div className="d-flex mt-5">
          <Button
            type="primary"
            htmlType="button"
            style={{ height: "40px", borderRadius: "12px" }}
            className="me-4"
            onClick={submitHandler}
          >
            <span className="fw-semibold font-sm">Export</span>
          </Button>
          <Button
            style={{ height: "40px", borderRadius: "12px" }}
            className="me-4"
            onClick={closeHandler}
          >
            <span className="fw-semibold font-sm">Cancel</span>
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DownloadSelectionModal;
