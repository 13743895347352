import { Modal, Input, Button } from "antd";
import { hideModal } from "../../../redux/reducers/modalSlice";
import { useAppDispatch } from "../../../redux/hooks";
import { errorToastMessage, toastMessage } from "../../../helpers/toastMessage";
import { setAppLoader } from "../../../redux/reducers/loaderSlice";

import { Formik } from "formik";
import * as yup from "yup";
import { sleep } from "../../../redux/actions/userAction";

type Props = {
  show: boolean;
};

const schema = yup.object().shape({
  emailSign: yup
    .string()
    .email("Please enter a valid Email ID.")
    .required("Email ID is Required"),
  passwordSign: yup
    .string()
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    )
    .required("Password is Required"),
});

const PhaseSignModal = (props: Props) => {
  const dispatch = useAppDispatch();

  const closeHandler = () => {
    dispatch(hideModal());
  };

  const submitHandler = async () => {
    try {
      dispatch(setAppLoader(true));
      await sleep(500);
      dispatch(setAppLoader(false));
      closeHandler();
    } catch (err) {
      dispatch(setAppLoader(false));
      errorToastMessage(err as Error);
    }
  };

  return (
    <Modal
      title={"Please confirm your email and password to Sign"}
      visible={props.show}
      onCancel={closeHandler}
      width={"45rem"}
      footer={null}
    >
      <div className="add-project-owner">
        <Formik
          initialValues={{
            passwordSign: "",
            emailSign: "",
          }}
          validationSchema={schema}
          onSubmit={() => {
            submitHandler();
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            handleBlur,
          }) => (
            <form onSubmit={handleSubmit} className="m-0">
              <div className="text-prime fw-medium mb-2">
                Please enter your email and password to sign this visit. All
                forms within the visit will also be signed.
              </div>
              <div className="w-100">
                <div className="text-secondary font-m fw-medium mb-1">
                  Email Address*
                </div>
                <Input
                  name="emailSign"
                  value={values.emailSign}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="singer-input"
                />
                <div className="input-error text-left mt-1">
                  {errors.emailSign && touched.emailSign && errors.emailSign}
                </div>
              </div>
              <div>
                <div className="text-secondary font-m fw-medium mb-1">
                  Password*
                </div>
                <Input.Password
                  name="passwordSign"
                  placeholder="Your password"
                  value={values.passwordSign}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="singer-input"
                />
                <div className="input-error text-left">
                  {touched.passwordSign && errors.passwordSign}
                </div>
              </div>
              <div>
                <Button
                  htmlType="submit"
                  style={{ height: "40px", borderRadius: "12px" }}
                  className="me-4"
                  type="primary"
                >
                  <span className="fw-semibold font-sm">Sign</span>
                </Button>
                <Button
                  onClick={closeHandler}
                  style={{ height: "40px", borderRadius: "12px" }}
                  htmlType="button"
                >
                  <span className="fw-semibold font-sm">Cancel</span>
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default PhaseSignModal;
